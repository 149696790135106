// eslint-disable-next-line no-redeclare,no-unused-vars
function view(xhr) {
  if (window.initialized) return;

  // parameter
  window.initialized = true;
  window.dataTmp = {};
  window.dataLoaded = [];
  window.testCases = {};
  window.calculated = {};
  window.click = 'click';
  window.active = true;
  window.errors = [];
  window.sidebar = null;
  window.menu = null;
  window.menuHistory = null;
  window.dashboard = null;
  window.help = null;
  window.views = null;
  window.viewStack = {};
  window.modalStack = [];
  window.historyStack = [];
  window.running = {};
  window.multiStore = 0;
  window.mainStore = null;
  window.storeFilter = null;
  window.pdfPreview = null;
  window.heightCalendar = null;
  window.tableCache = {};
  window.noLogoutInfo = false;
  window.dropzone = null;
  window.config = null;
  window.points = {};
  window.elements = {tables: {}, lists: {}};
  window.calcCommands =
    'date|datetime|week|year|year2|month|day|time|microtime|amount|count|abs|spots|sum|check|round|round2|oposite|func|translit|history|concat|color|merge|cond|last';
  window.calcVars = [
    'now',
    'today',
    'time',
    'month',
    'month2',
    'year',
    'year2',
    'user',
    'store',
    'multiStore',
    'url',
    'notificationPermission',
    'jsData',
    'screenshot',
    'role',
    'roll',
    'read',
  ];

  // parameter from backend
  window.parameter = xhr.parameter;
  window.fields = xhr.fields;
  window.fieldsMenu = xhr.menu;
  window.domain = xhr.domain;
  window.test = xhr.test;
  window.develop = xhr.develop;
  window.data = xhr.data;
  window.dialogForms = xhr.dialogForms;
  window.client = xhr.client;
  window.industry = xhr.industry;
  window.info = xhr.infoLabels;
  window.windowId = xhr.windowId;
  window.calcVars2 = xhr.calcVars;
  window.dropdowns = xhr.dropdowns;
  window.design = xhr.design;

  if (!isset(xhr.tests)) window.noTests = true;
  else window.tests = xhr.tests;
  if (xhr.userMessage) window.userMessage = parseInt(xhr.userMessage);
  if (xhr.pdfCss) window.pdfCss = xhr.pdfCss;
  if (xhr.branches) window.branches = xhr.branches;
  window.debug = getLocalFlag('debug');
  $(document.body).removeClass('login').attr('data-mdb-no-jquery', true);
  $('#login').remove();
  if (window.FontAwesomeConfig) FontAwesomeConfig.keepOriginalSource = false;

  // document title
  if (develop) document.title = xhr.title + ' - Develop';
  else if (test) document.title = xhr.title + ' - Sandbox';
  else document.title = xhr.title;
  window.title = window.titleOrig = document.title;

  // window events
  addHotkeys();
  window.onresize = function () {
    $.each(viewStack, function (ident, view) {
      if (view.setHeight) view.setHeight();
    });
  };
  if (mobile) prepareMobile();
  if (!window.requestIdleCallback)
    window.requestIdleCallback = function (func) {
      func();
    };
  //window.onpopstate = function(e){ if (e.state) ajax(e.state.modul); return; };

  window.user = getObjectValue(xhr.data, ['user', xhr.user]);
  if (!user) user = {};
  window.role = user.roll;
  window.store = getObjectValue(xhr.data, ['store', xhr.store]);
  if (!store) store = {};
  if (isFunction(moment)) moment.suppressDeprecationWarnings = true;
  if (!user.language) user.language = 'de';
  // if (user.admin && window.test && !window.adminSave && !xhr.second){
  // 	loadJs('admin.js',function(){ xhr.second = true; first(param,xhr); });
  // 	return;
  // }

  // build
  if (xhr.next2) load(xhr.next2, xhr);
  else build();

  // login cookie
  if (Cookies.get('login')) {
    if (store.autoLogin === 0) Cookies.remove('login');
    else Cookies.set('login', Cookies.get('login'), {expires: 7});
  }

  // optimizeMemory();
  // if (test && navigator.serviceWorker) navigator.serviceWorker.register('js/serviceWorker.js');
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function prepareMobile() {
  // click = 'tap'; -> bug on android
  $(window).on('gesturestart', function (evt) {
    evt.preventDefault();
  });
  window.scrollTo(0, 1);
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function optimizeMemory() {
  if (window.performance && performance.memory)
    setInterval(function () {
      console.log(performance.memory);
      if (performance.memory.totalJSHeapSize > 1000000000) data = {};
    }, 300000);
}

document.addEventListener('DOMContentLoaded', function () {
  // fix autocomplete position in dom
  new MutationObserver((mutations) => {
    for (const mutation of mutations) {
      for (const node of mutation.addedNodes) {
        if (node.nodeType === 1 && node.matches('.autocomplete-input')) {
          node.addEventListener('click', () => {
            const targetNodeName = node.getAttribute('aria-owns');
            const xCord = node.getBoundingClientRect().left;
            const yCord = node.getBoundingClientRect().top;
            const targetNode = document.getElementById(targetNodeName);
            targetNode.attributeStyleMap.clear();
            targetNode.style.position = 'absolute';
            targetNode.style.left = `${xCord}px`;
            targetNode.style.top = `${yCord}px`;
            targetNode.style.zIndex = '9999';
            targetNode.style.setProperty('margin-left', '0px', 'important');
            targetNode.style.marginTop = '85px';
          });
        }
      }
    }
  }).observe(document.body, {childList: true, subtree: true});
});
